import React, { Component } from "react";
import { navigate } from "@reach/router";
import { Formik } from "formik";
import { updateUserDetails, userDetails } from "../../services/moocfi";
import CountrySelect from "../SignUp/CountrySelect";
import LanguageSelect from "../SignUp/LanguageSelect";
import withAuthentication from "../Session/withAuthentication";
import StyledForm from "../StyledForm";
import { SmallContainer } from "../Grid";
import ExtraInfoForm from "../ExtraInfo";
import TextF8 from "../TextF8";
import { StyledF7, ErrorMessage, SubmitButton, INITIAL_STATE } from "../SignUp";
import { createMailchimpSubscriber } from "../../services/zapier";
import { FormattedMessage, injectIntl } from "react-intl";

import _ from "lodash";

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

class MissingInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, country: "Finland", loading: true };
  }

  handleSubmit = values => {
    const extraFields = {
      deadline: "self-paced",
      marketing: values.marketing,
      research: values.research,
      helsinki_university: values.helsinki_university,
      open_university: values.open_university,
      language: this.props.langKey || "en",
      country: values.country,
      postal_code: values.postal_code,
      accept_policy_versions: values.accept_policy_versions ? [process.env.PRIVACY_VERSION] : []
    };

    const userField = {
      first_name: values.firstName,
      last_name: values.lastName,
      organizational_id: values.studentNumber,
      email: values.email
    };

    return new Promise((resolve, reject) => {
      userDetails().then(previousUserDetails => {
        const email = previousUserDetails.email;
        updateUserDetails({ userField, extraFields })
          .then(res => {
            createMailchimpSubscriber({
              ...values,
              email: email,
              language: extraFields.language,
              country: values.country
            });
            resolve(res);
          })
          .then(() => {
            navigate(`${this.props.redirect}`);
          })
          .catch(error => {
            this.setState(updateByPropertyName("error", JSON.stringify(error)));
            reject(error);
          });
      });
    });
  };

  componentDidMount() {
    userDetails().then(user => {
      if (user) {
        let data;
        if (user && user.extra_fields) {
          data = _.omit(user.extra_fields, "language");
          this.setState({
            firstName: user.user_field.first_name,
            lastName: user.user_field.last_name,
            postal_code: user.extra_fields.postal_code,
            deadline: "self-paced",
            open_university: user.extra_fields.open_university,
            marketing: user.extra_fields.marketing,
            research: user.extra_fields.research
          }, () => {
            this.setState({
              loading: false
            })
          })
        }
        if (data && Object.keys(data).length > 0 && false) {
          this.setState({ complete: true });
        }
      }
    });
  }

  render() {
    if (this.state.complete) {
      navigate(`${this.props.location.search.substring(1)}`);
      return null;
    }

    const validate = (values, props) => {
      let errors = {};

      if (!values.firstName) {
        errors.first_name = this.props.intl.formatMessage({
          id: "pwChange.required"
        });
      }

      if (!values.lastName) {
        errors.last_name = this.props.intl.formatMessage({
          id: "pwChange.required"
        });
      }

      if (!values.country) {
        errors.country = this.props.intl.formatMessage({
          id: "pwChange.required"
        });
      }

      if (!values.accept_policy) {
        errors.accept_policy_versions = this.props.intl.formatMessage({
          id: "pwChange.required"
        });
      }

      if (Object.keys(errors).length) {
        errors.form = this.props.intl.formatMessage({
          id: "signUp.error"
        });
      }

      return errors;
    };

    if (this.state.loading) {
      return (
        <div></div>
      )
    }

    return (
      <div>
        <Formik
          // initialValues={INITIAL_STATE}
          initialValues={this.state}
          validate={validate}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            this.handleSubmit(values).then(
              res => {
                setSubmitting(false);
                this.setState({ complete: true });
              },
              errors => {
                setSubmitting(false);
                setErrors(errors);
              }
            );
          }}
          render={({
            values,
            errors,
            isValid,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <StyledForm>
              <form onSubmit={handleSubmit}>
                <SmallContainer pt={6}>
                  <StyledF7>
                    <FormattedMessage id="missingInfo.body" />
                  </StyledF7>
                  <StyledF7>
                    <FormattedMessage id="missingInfo.privacyPolicyUpdateBody" />
                    {" "}<a href={this.props.intl.formatMessage({ id: "routes.PRIVACY" })}><FormattedMessage id="missingInfo.privacyPolicyUpdateLink" /></a>
                  </StyledF7>
                </SmallContainer>
                <SmallContainer pt={6}>
                  <fieldset>
                    <label htmlFor="firstName">
                      <FormattedMessage id="userDetails.firstName" />
                    </label>
                    <input
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder={this.props.intl.formatMessage({
                        id: "userDetails.firstName"
                      })}
                    />
                    <label htmlFor="lastName">
                      <FormattedMessage id="userDetails.lastName" />
                    </label>
                    <input
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder={this.props.intl.formatMessage({
                        id: "userDetails.lastName"
                      })}
                    />
                    {/*
                    <label htmlFor="studentNumber">
                      <FormattedMessage id="signUp.studentNumber" />
                    </label>
                    <input
                      type="text"
                      name="studentNumber"
                      id="studentNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.studentNumber}
                    />
                    */}
                    <select

                      name="country"
                      id="country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country ? values.country : "Finland"}
                    >
                      <CountrySelect />
                    </select>
                    <label htmlFor="postal_code">
                      <FormattedMessage id="signUp.postalCode" />
                    </label>
                    <input
                      type="text"
                      name="postal_code"
                      id="postal_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postal_code}
                    />
                    <label htmlFor="language">
                      <FormattedMessage id="userDetails.language" />
                    </label>
                    <select
                      value={values.language}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="language"
                      name="language"
                    >
                      <LanguageSelect />
                    </select>
                  </fieldset>

                  <ExtraInfoForm
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    intl={this.props.intl}
                  />
                </SmallContainer>
                <TextF8 textAlign="center" mt={0}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.intl.formatMessage({
                      id: "routes.PRIVACY"
                    })}
                  >
                    <FormattedMessage id="missingInfo.privacyPolicy" />
                  </a>
                </TextF8>
                <TextF8 textAlign="center" mt={0}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={this.props.intl.formatMessage({
                      id: "routes.TERMS"
                    })}
                  >
                    <FormattedMessage id="globals.menu.termsofService" />
                  </a>
                </TextF8>
                <SubmitButton

                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting
                    ? this.props.intl.formatMessage({
                      id: "globals.submitting"
                    })
                    : this.props.intl.formatMessage({ id: "globals.submit" })}
                </SubmitButton>
                {touched && Object.keys(errors).length > 0 && (
                  <ErrorMessage>
                    <FormattedMessage id="missingInfo.error" />
                  </ErrorMessage>
                )}
              </form>
            </StyledForm>
          )}
        />
      </div>
    );
  }
}

export default withAuthentication(injectIntl(MissingInfoForm));
