import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { langs } from "../../../data/languages";

const LanguageSelect = ({ country }) => {
  const availableLanguages = {
    "United Kingdom": ["en"],
    Germany: ["de"],
    Finland: ["fi", "se"],
    Latvia: ["lv"],
    Norway: ["no"],
    Sweden: ["se"],
    Estonia: ["ee"],
    Malta: ["mt"],
    France: ["fr"],
    Lithuania: ["lt"],
    Belgium: ["nl-be", "fr-be"],
    Ireland: ["en-ie", "ga"],
    Italy: ["it"],
    Poland: ["pl"],
    Netherlands: ["nl"],
    Denmark: ["da"],
    Croatia: ["hr"],
    Slovakia: ["sk"],
    Luxembourg: ["en-lu"],
    Romania: ["ro"],
    Iceland: ["is"],
    Austria: ["de-at"],
    Bulgaria: ["bg"],
    "Czech Republic": ["cs"],
    Slovenia:["sl"],
    Portugal: ["pt"],
    Spain: ["es"],
    Greece: ["el"],
    default: ["en"]
  };

  const defaultLanguage = availableLanguages.default;

  const collator = new Intl.Collator();

  return (
    <Fragment>
      {availableLanguages[country] ? (
        availableLanguages[country].map(language => (
          <FormattedMessage key={language} id={`languages.${language}.long`}>
            {message => <option value={language}>{message}</option>}
          </FormattedMessage>
        ))
      ) : (
        <FormattedMessage
          key={defaultLanguage}
          id={`languages.${defaultLanguage}.long`}
        >
          {message => <option value={defaultLanguage}>{message}</option>}
        </FormattedMessage>
      )}
      <option value="" disabled />
      {langs
        .sort(function (o1, o2) {
          return collator.compare(o1, o2);
        })
        .map(language => (
          <FormattedMessage key={language} id={`languages.${language}.long`}>
            {message => <option value={language}>{message}</option>}
          </FormattedMessage>
        ))}
    </Fragment>
  );
};

export default injectIntl(LanguageSelect);
